import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/BuildIt";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
// eslint-disable-next-line no-unused-vars
import SideScrollFragment from "@fragments/SideScrollFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query BuildItQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "canYouBuildIt", siteId: $siteId) {
        ... on Craft_canYouBuildIt_canYouBuildIt_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          # climate
          climateLabel: heading1
          climateHeading: heading2
          climateDescriptor: descriptor1
          # grassroots
          grassrootsImage: image0 {
            ...ImageFragment
          }
          grassrootsHeading: heading3
          grassrootsCopy: copy0
          grassrootsVideo: video0
          # crisis
          crisisLabel: heading4
          crisisHeading: heading5
          crisisDescriptor: descriptor2
          # determination
          determinationHeading: heading8
          determinationSideScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          # movements
          movementsLabel: heading11
          movementsHeading: heading9
          movementsDescriptor: descriptor4
          # historic
          historicHeading: heading10
          historicSideScroll: sideScrollEmbed1 {
            ...SideScrollFragment
          }
          # partnership
          partnershipSectionLabel: heading6
          partnershipLabel: heading7
          partnershipHeading: heading14
          partnershipImage: image1 {
            ...ImageFragment
          }
          partnershipDescriptor: descriptor3
          partnershipBodyImage: image2 {
            ...ImageFragment
          }
          partnershipCopy: copy1
          # momentum
          momentumLabel: heading12
          momentumHeading: heading13
          momentumCopy: copy2
          # values
          valuesHeading: heading15
          valuesImage: image3 {
            ...ImageFragment
          }
          values: moveBlocks {
            ... on Craft_moveBlocks_block_BlockType {
              uid
              image {
                ...ImageFragment
              }
              descriptor
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    introHeading,
    introDescriptor,
    climateLabel,
    climateHeading,
    climateDescriptor,
    grassrootsImage,
    grassrootsHeading,
    grassrootsCopy,
    grassrootsVideo,
    crisisLabel,
    crisisHeading,
    crisisDescriptor,
    determinationHeading,
    determinationSideScroll,
    movementsLabel,
    movementsHeading,
    movementsDescriptor,
    historicHeading,
    historicSideScroll,
    partnershipSectionLabel,
    partnershipLabel,
    partnershipHeading,
    partnershipImage,
    partnershipDescriptor,
    partnershipBodyImage,
    partnershipCopy,
    valuesImage,
    valuesHeading,
    momentumLabel,
    momentumHeading,
    momentumCopy,
    values,
  } = entry;
  return {
    meta: {
      title,
    },
    title,
    intro: { heading: introHeading, description: introDescriptor },
    climate: {
      label: climateLabel,
      heading: climateHeading,
      description: climateDescriptor,
    },
    grassroots: {
      image: resolveImage(grassrootsImage),
      heading: grassrootsHeading,
      copy: grassrootsCopy,
      video: grassrootsVideo,
    },
    crisis: {
      label: crisisLabel,
      heading: crisisHeading,
      description: crisisDescriptor,
    },
    determination: {
      heading: determinationHeading,
      sideScroll: resolveSideScroll(determinationSideScroll),
    },
    movements: {
      label: movementsLabel,
      heading: movementsHeading,
      description: movementsDescriptor,
    },
    historic: {
      heading: historicHeading,
      sideScroll: resolveSideScroll(historicSideScroll),
    },
    partnership: {
      label: partnershipSectionLabel,
      subheading: partnershipLabel,
      heading: partnershipHeading,
      image: resolveImage(partnershipImage),
      lead: partnershipDescriptor,
      bodyImage: resolveImage(partnershipBodyImage),
      copy: partnershipCopy,
    },
    momentum: {
      label: momentumLabel,
      heading: momentumHeading,
      copy: momentumCopy,
    },
    values: {
      heading: valuesHeading,
      image: resolveImage(valuesImage),
      values: values.map(v => ({
        ...v,
        image: resolveImage(v.image),
      })),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CanYouBuildItQuery"
    {...props}
  />
);

export default Page;
