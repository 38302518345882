import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const Hero = loadable(() => import("@organisms/Hero"));
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));
const GrowingImageValues = loadable(() =>
  import("@templates/GrowingImageValues")
);
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);
const StoryOfChange = loadable(() => import("@templates/StoryOfChange"));

const DreamPage = ({ data }) => {
  const {
    title,
    intro,
    climate,
    grassroots,
    crisis,
    determination,
    movements,
    historic,
    partnership,
    momentum,
    values,
  } = data;

  return (
    <DefaultPageContainer>
      <Frame
        title={intro?.heading || title}
        next="/can-you-move-it"
        previous="/can-you-dream-it"
      />
      <Hero {...intro} largerHeadingSpace />
      <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
      <Section id="climate">
        <SectionIntro {...climate} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopy {...grassroots} smallerSpacer />
      </Section>
      {/* respond */}
      <Section id="respond">
        <SectionIntro {...crisis} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...determination} headingAlign="center" />
      </Section>
      {/* policy */}
      <Section id="policy">
        <div className="relative z-10 border-t-3 border-white pt-12 md:ml-14" />
        <SectionIntro {...movements} />
        <GrowingImageSlide {...historic} headingAlign="center" />
        <div className="texture-pattern relative z-[100] bg-viridian">
          <div className="relative z-10 border-t-3 border-white pt-12 md:ml-14" />
          <StoryOfChange subheadingPosition="top" {...partnership} />
        </div>
      </Section>
      {/* values */}
      <Section id="values">
        <div className="relative z-10 border-t-3 border-white pt-12 md:ml-14" />
        <SectionIntro {...momentum} descriptionStyle="serif" />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageValues {...values} />
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
