import resolveImage from "./resolveImage";
import resolveAudiogram from "./resolveAudiogram";

export const resolveSideScrollBlock = (b, i) => {
  switch (b.type) {
    case "image":
      return { ...b, image: resolveImage(b.image), reverse: !!(i % 2) };
    case "audiograms":
      return {
        ...b,
        audiograms: b.audiograms
          .map(resolveAudiogram)
          .map(a => a.audiograms[0]),
      };
    default:
      return { ...b };
  }
};

const resolveSideScroll = _s => {
  const s = _s?.[0] || _s;
  const { title, descriptor, copy, image } = s;
  return {
    ...s,
    intro: {
      heading: title,
      descriptor,
      copy,
      image: resolveImage(image),
    },
    slides: s.sideScroll.map(resolveSideScrollBlock),
  };
};

export default resolveSideScroll;
